.about {
    /* make height of section 100 pixels */
    height:600px;
    background-image: url("https://i.imgur.com/xdoFzYn.png");
    /* make font color white*/
    color: white;
    /* center text */
    text-align: center;
}

.summaryCard{
    position: relative;
    top: 600px;
}