.card{
    background-color: #364145;
    border-radius: 55px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: auto;
    width: 90%;
    max-width: 50rem;
    padding:0 1rem 1rem 1rem;
    color: black;
}

.cardHeader{
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
    color: black;
    font-family: "Source Code Pro";
}

.cardContent{
    background-color: black;
    border-radius: 55px;
    padding: 1rem;
    text-align: center;
    color: green;
    font-family: "Source Code Pro";
}