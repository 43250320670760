.footer-cont {
    margin: auto;
    max-width: 800px;
    text-align: center;
}

.footer-icon-cont{

}

.footer-icon{
    margin: 10px 10px 10px 10px;
}