.portfolio-image{
    height: 550px; /* Fixed height for carousel items */
    width: 100%;
    margin: auto;
    background-position: center; /* Center the background image */
    background-size: cover; /* Cover the entire item area */
    background-repeat: no-repeat;
}
 @media (max-width: 767px) {
     .portfolio-container {
         margin: auto;
         max-width: 100%;
     }
     .portfolio-image {
         margin: auto;
         max-width: 100%;
     }
     .portfolio-header {
         margin: auto;
         max-width: 100%;
     }
 }


.portfolio-container{
    min-height: 460px;
    margin: auto;
    max-width: 100%;
    height: 300px;
}

.portfolio-header{
    max-width: 800px;
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    margin: auto;
}