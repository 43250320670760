.container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container html {
    height: 100%;
}

.container body {
    min-height: 100%;
    background: #eee;
    font-family: "Source Code Pro";
    font-weight: 400;
    color: #222;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 50px;
}

.container {
    max-width: 800px;
    background: #fff;
    margin: 0px auto 0px;
    box-shadow: 1px 1px 2px #DAD7D7;
    border-radius: 3px;
    padding: 40px;
    margin-top: 50px;
}

.container .header {
    margin-bottom: 30px;
}

.full-name {
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.first-name {
    font-weight: 700;
}

.last-name {
    font-weight: 300;
}

.contact-info {
    margin-bottom: 20px;
}

.email,
.phone {
    color: #999;
    font-weight: 300;
}

.separator {
    height: 10px;
    display: inline-block;
    border-left: 2px solid #999;
    margin: 0px 10px;
}

.about{

}

.desc{

}

.position {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
    text-decoration: underline;
}



.details {
    line-height: 20px;
}
.section {
    margin-bottom: 40px;
}

.section:last-of-type {
    margin-bottom: 0px;
}

.section__title {
    letter-spacing: 2px;
    color: #54AFE4;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.section__list-item {
    margin-bottom: 40px;
}

.section__list-item:last-of-type {
    margin-bottom: 0;
}

.left,
.right {
    vertical-align: top;
    display: inline-block;
}

.left {
    width: 60%;
}

.right {
    tex-align: right;
    width: 39%;
}

.name {
    font-weight: bold;
}

.addr{

}

.duration{

}

.container a {
    text-decoration: none;
    color: blue;
    font-style: italic;
}

.container a:hover {
    text-decoration: underline;
    color: blue;
}

.skills {

}

.skills__item {
    margin-bottom: 10px;
}

.skills__item .right {
}

.container input {
    display: none;
}

.container label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #C3DEF3;
    border-radius: 20px;
    margin-right: 3px;
}
.text{
}
input:checked + label {
    background: #79A9CE;
}









